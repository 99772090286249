import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

export class HttpRequestOptions {
    body?: any = {};
    headers?: HttpHeaders;
    observe?: 'response' = 'response';
    params?: HttpParams;
    reportProgress?: boolean = false;
    responseType?: 'arraybuffer' | 'json' | 'blob';
    withCredentials?: boolean = false;
}

@Injectable({
    providedIn: 'root'
})
export class AuthHttpService {
    constructor(private http: HttpClient) {

    }

    public get(url: string, type: 'blob', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<Blob>>;
    public get(url: string, type: 'arraybuffer', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<ArrayBuffer>>;
    public get<T>(url: string, type: 'json', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;
    public get(url: string, type: 'text', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<string>>;
    public get<T>(url: string, type?: any, opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;

    public get(url: string, type: any, opts?: Partial<HttpRequestOptions>) {
        type = type || 'json';
        return <any>this.request('GET', url, <any>type, opts);
    }

    public delete(url: string, body?: any, type?: 'blob', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<Blob>>;
    public delete(url: string, body?: any, type?: 'arraybuffer', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<ArrayBuffer>>;
    public delete<T>(url: string, body?: any, type?: 'json', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;
    public delete<T>(url: string, body?: any, type?: any, opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;

    public delete(url: string, body?: any, type?: any, opts?: Partial<HttpRequestOptions>) {
        type = type || 'json';

        if(!!body) {
            opts = opts || {};
            opts.body = body;
        }

        return <any>this.request('DELETE', url, <any>type, opts);
    }

    public post(url: string, body: any, type: 'blob', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<Blob>>;
    public post(url: string, body: any, type: 'arraybuffer', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<ArrayBuffer>>;
    public post<T>(url: string, body: any, type: 'json', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;
    public post<T>(url: string, body: any, type?: any, opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;

    public post(url: string, body: any, type: any, opts?: Partial<HttpRequestOptions>) {
        opts = opts || {};
        opts.body = body;

        return <any>this.request('POST', url, <any>type, opts);
    }

    public postForm(url: string, form: FormData) {
        const requestUrl = `${environment.apiUrl}${url}`;

        return <any>this.http.post(requestUrl, form);
    }

    public patch(url: string, body: any, type: 'blob', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<Blob>>;
    public patch(url: string, body: any, type: 'arraybuffer', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<ArrayBuffer>>;
    public patch<T>(url: string, body: any, type: 'json', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;
    public patch<T>(url: string, body: any, type?: any, opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;

    public patch(url: string, body: any, type: any, opts?: Partial<HttpRequestOptions>) {
        opts = opts || {};
        opts.body = body;

        return <any>this.request('PATCH', url, <any>type, opts);
    }

    public put(url: string, body: any, type: 'blob', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<Blob>>;
    public put(url: string, body: any, type: 'arraybuffer', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<ArrayBuffer>>;
    public put<T>(url: string, body: any, type: 'json', opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;
    public put<T>(url: string, body: any, type?: any, opts?: Partial<HttpRequestOptions>): Observable<HttpResponse<T>>;

    public put(url: string, body: any, type: any, opts?: Partial<HttpRequestOptions>) {
        opts = opts || {};
        opts.body = body;

        return <any>this.request('PUT', url, <any>type, opts);
    }

    private request(method: string, url: string, type: 'blob' | 'arraybuffer' | 'json', opts?: Partial<HttpRequestOptions>): Observable<any> {
        opts = opts || new HttpRequestOptions();
        opts.observe = opts.observe || 'response';
        opts.headers = opts.headers || new HttpHeaders();

        opts.responseType = opts.responseType || type;

        const requestUrl = `${environment.apiUrl}${url}`;

        return <any>this.http.request(method, requestUrl, {
            headers: opts.headers,
            observe: 'response',
            body: opts.body,
            params: opts.params,
            reportProgress: opts.reportProgress,
            responseType: type,
            withCredentials: false
        });
    }
}
