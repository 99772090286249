import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { SelectedCompany } from "@appcore/services/selected-company";
import { Router } from "@angular/router";

@Component({
    selector: 'app-logo',
    template: `
        <img [fxHide]="isDark" [class]="classList"
             src="../../../../assets/logo.png"
             alt="Ombord"
             (click)="navigateToFrontPage()">
        <!-- TODO RICHARD isDark is currently only used in crew app. The routerlink above does not work for crew app.-->
        <img *ngIf="isDark" [class]="classList"
             src="../../../../assets/logo-dark.png"
             alt="Ombord"
             (click)="navigateToFrontPage()">
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit, OnDestroy {
    @Input() classList = '';
    isDark = false;

    private obs: MutationObserver | undefined;

	constructor(
        @Inject(DOCUMENT) private document: Document,
        private changeDetector: ChangeDetectorRef,
        public company: SelectedCompany,
        private router: Router) {

    }

    ngOnInit() {
        this.checkTheme();
        this.obs = new MutationObserver(() => {
            this.checkTheme();
        });
        this.obs.observe(this.document.documentElement, {attributes: true, attributeFilter: ['class'], childList: false, characterData: false});
    }

    ngOnDestroy() {
        this.obs?.disconnect();

    }

    private checkTheme(){
        this.isDark = this.document.documentElement.classList.contains('theme-dark');
        this.changeDetector.markForCheck();
    }

    navigateToFrontPage() {
        let currentRoute = this.router.url;
        if (currentRoute.includes('user'))
            this.router.navigate(['/dashboard/user'])
        else if (currentRoute.includes('master-view')) {
            this.router.navigate([`/dashboard/master-view/${this.company.lastShippingCompany}`])
        }
        else
            this.router.navigate([`/dashboard/organization/view/${this.company.lastShippingCompany}`])
    }
}
