import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Data,
    Route, Router,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';
import {catchError, map, mergeMap, Observable, of, take} from 'rxjs';
import {AuthGuard, AuthService} from "@auth0/auth0-angular";
import {UserService} from "@appcore/services";
import {RouterHistoryService} from "@appcore/services/router-history.service";

@Injectable({
    providedIn: 'root'
})
export class AccessGuard extends AuthGuard {
    constructor(auth: AuthService, private user: UserService, private router: Router, private routerHistory: RouterHistoryService) {
        super(auth);
    }

    override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return super.canActivate(route, state)
            .pipe(
                take(1),
                mergeMap(authed => this.checkAccess(route, authed))
            );
    }

    override canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return super.canLoad(route, segments)
            .pipe(
                take(1),
                mergeMap(authed => this.checkAccess(route, authed)),
            );

    }

    private checkAccess(route: { data?: Data}, authenticated: boolean): Observable<boolean> {
        const access = route?.data == null ? [] : route.data['access'] as Array<string> || [];
        if (access == null || access.length == 0)
            throw {message: 'Guard must have a "data.access" attribute with an array of allowed values', route};

        if (!authenticated) {
            if (this.router.url !== '/login') {
                this.routerHistory.setCurrentRoute();
                this.router.navigate(['/login'])
            }
            return of(authenticated);
        }

        const noAccess = (err: any = null) => {
            if (this.router.url !== '/noaccess') {
                this.router.navigate(['/noaccess'], {state: {error: err}});
            }
        }

        return this.user.userProfile$.pipe(
            take(1),
            map(user => {
                if (user == null || !access.some(a => user.access.indexOf(a) !== -1)) {
                    noAccess();
                    return false;
                }

                return true;
            }),
            catchError((err) => {
                noAccess(err);
                return of(false);
            }));
    }
}
