import {Directive, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {icons} from "@appcore/icons";

@Directive()
export abstract class ComponentBase implements OnDestroy {
    private _componentDestroyedSub = new Subject<boolean>();
    public icons = icons;
    protected constructor() {

    }

    get componentDestroyed(){
        return this._componentDestroyedSub.asObservable();
    }

    public ngOnDestroy(): void {
        this._componentDestroyedSub.next(true);
        this._componentDestroyedSub.complete();
    }
}
