import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
    selector: 'app-loading',
    template: `
        <mat-progress-bar *ngIf="mode === 'bar' else spinner" mode="indeterminate" [color]="color"></mat-progress-bar>
        <ng-template #spinner>
            <mat-progress-spinner mode="indeterminate" [color]="color"></mat-progress-spinner>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
    @Input() color: 'primary' | 'accent' | 'warn' | undefined;
    @Input() mode: 'spinner' | 'bar' = 'bar';
}
