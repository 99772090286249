import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {take} from "rxjs";
import {Router} from "@angular/router";
import {UserService} from "@appcore/services/user.service";
import {RouterHistoryService} from "@appcore/services/router-history.service";
import {HttpParams} from "@angular/common/http";

@Component({
    selector: 'app-login',
    template: `
        <app-centered class="login">
            <app-logo></app-logo>

            <p></p>
            <p></p>

            <div *ngIf="authed === false else loading" fxLayout="row" fxLayoutAlign="center center">
                <app-auth-button mode="icon-text"></app-auth-button>
            </div>
        </app-centered>

        <ng-template #loading>
            <app-loading mode="spinner"></app-loading>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.Default
})
export class LoginComponent implements OnInit {
    public authed?: boolean | null = null;

    constructor(public user: UserService, private router: Router, private routeHistory: RouterHistoryService) {

    }

    ngOnInit(): void {
        this.user.isAuthenticated$.pipe(take(1)).subscribe(authed => {
            this.authed = authed;
            if (!authed)
                return;

            this.user.defaultRoute.pipe(take(1)).subscribe(defaultRoute => {
                let previousRoute = this.routeHistory.lastRoute;

                if (previousRoute != null && previousRoute != '/'
                    && previousRoute.indexOf('login') === -1 && previousRoute.indexOf('noaccess') === -1) {
                    console.log('Go to previous route: ' + previousRoute);
                    let params: any = {};
                    if (previousRoute.includes('?')) {
                        const split = previousRoute.split('?');
                        previousRoute = split[0];
                        const httpParams = new HttpParams({fromString: split[1]});
                        for (const e of httpParams.keys())
                            params[e] = httpParams.get(e);
                    }
                    this.router.navigate([previousRoute], {queryParams: params}).catch(() => {
                        console.warn('Failed to navigate to ' + previousRoute + ', navigate to default route');
                        this.router.navigate([defaultRoute]);
                    });

                } else {
                    this.router.navigate([defaultRoute]);
                }
            });
        });
    }
}
