import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { IconComponent } from "../../icon/icon.component";

@Component({
    selector: 'app-icon-text-button',
    template: `
        <button class="icon-text-button" [disabled]="disabled" mat-raised-button type="button" [color]="color">
            <ng-container *ngTemplateOutlet="inner"></ng-container>
        </button>

        <ng-template #inner>
            <div fxLayout="row" fxLayoutAlign="start center">
                <app-icon [icon]="icon" [iconSet]="iconSet" [color]="reverse(color)"></app-icon>
                <span>{{label}}</span>
            </div>
        </ng-template>
    `,
    styles: [`
        app-icon {
            position: relative;
            margin-left: 0.6em;
            right: 2.0em;
            font-size: 18px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconTextButtonComponent extends IconComponent {
    @Input() label = '';
    @Input() disabled = false;

    reverse(color: ThemePalette){
        switch (color){
            case 'primary':
                return 'accent';
            case 'accent':
                return 'primary';
            default:
                return undefined;
        }
    }
}
