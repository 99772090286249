import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {ComponentBase} from "@appcore/componentbase";

@Component({
    selector: 'app-root',
    template: `
        <mat-progress-bar mode="indeterminate" *ngIf="authService.isLoading$ | async"></mat-progress-bar>
        <router-outlet></router-outlet>
    `,
    changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent extends ComponentBase {

    constructor(public authService: AuthService) {
        super();
    }
}
