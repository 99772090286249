import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class RouterHistoryService {
    constructor(private router: Router, private location: Location) {

    }

    /**
     * Retrieves and consumes the last known route
     */
    get lastRoute() {
        const previous = sessionStorage.getItem('last-route');
        if (previous == null)
            return null;

        sessionStorage.removeItem('last-route');
        return previous;
    }

    /**
     * Stores the current path for retrieval later.
     */
    setCurrentRoute() {
        sessionStorage.setItem('last-route', this.location.path() || '');
    }
}
