import { HttpClient } from '@angular/common/http';
import {
    Translation,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { TranslocoLocaleModule } from "@ngneat/transloco-locale";

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@NgModule({
    exports: [TranslocoModule, TranslocoLocaleModule],
    imports: [TranslocoLocaleModule.forRoot(
        {
            langToLocaleMapping: {
                en: 'en-GB',
                nn: 'nn-NO',
                nb: 'nb-NO',
            },
            // localeConfig: {
            //     global: {
            //         date: {
            //             dateStyle: 'short',
            //             timeStyle: 'short'
            //         }
            //     },
            //     localeBased: {
            //         'nn': {
            //
            //         }
            //     }
            // }
        }
    )],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: ['en', 'nn', 'nb'],
                defaultLang: 'en',
                fallbackLang: ['en', 'nn', 'nb'],
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: false, // FIXME: Sidenav does not work with rerender
                prodMode: environment.production,
            })
        },
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        }
    ]
})
export class TranslocoRootModule {
}
