import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { BooleanInput } from "@angular/cdk/coercion";
import { ThemePalette } from "@angular/material/core";

@Component({
    selector: 'app-button',
    template: `
        <button class="button" [type]="type" [disabled]="disabled" style="width: 100%" mat-raised-button (click)="onClick.emit()" [color]="color">
            <ng-content></ng-content>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
    @Input() type: 'button' | 'submit' | 'reset' | undefined;
    @Input() color: ThemePalette | undefined;
    @Output() onClick = new EventEmitter();

    private _disabled: boolean | undefined;

    @Input() set disabled(value: BooleanInput) {
        this._disabled = ButtonComponent.parseBool(value);
    }

    get disabled(): boolean {
        return this._disabled ?? false;
    }

    private static parseBool(value: BooleanInput): boolean {
        switch (value) {
            case null:
            case undefined:
                return false;
            default:
                return typeof (value) === 'boolean' ? value : value !== 'false'
        }
    }
}


@Component({
    selector: 'app-control-button',
    template: `
        <button [formControl]="formControl" [type]="type" [disabled]="disabled" style="width: 100%" mat-stroked-button (click)="onClick.emit()">
            <ng-content></ng-content>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlButtonComponent extends ButtonComponent {
    @Input() formControl = new UntypedFormControl();
}
