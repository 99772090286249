import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-centered',
    template: `
        <div class="centered-container">
            <ng-content></ng-content>
        </div>
    `,
    styles: [`
        :host {
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .centered-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CenteredComponent {
}
