import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {ReplaySubject} from "rxjs";
import {UserService} from "@appcore/services/user.service";
import {ComponentBase} from "@appcore/componentbase";

@Component({
    selector: 'app-no-access',
    template: `
        <app-centered *transloco="let t">

            <app-logo></app-logo>

            <p></p>
            <p></p>

            <p>{{t('shared.no.access')}}</p>
            <div *ngIf="userService.isAuthenticated$ | async else back">
                <app-auth-button mode="icon-text"></app-auth-button>
            </div>


            <b class="color-warn" style="margin-top: 1rem" *ngIf="err | async as error">({{error.type}})</b>
        </app-centered>

        <ng-template #back>
            <app-button [routerLink]="['/']">Tilbake</app-button>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoAccessComponent extends ComponentBase implements OnDestroy{
    err = new ReplaySubject<any>(1);

    constructor(private router: Router, public userService: UserService) {
        super();
        const extras = this.router.getCurrentNavigation()?.extras;
        if (extras?.state != null)
            this.err.next(extras?.state['error']);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.err.complete();
    }
}
