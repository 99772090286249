import { DEFAULT_CURRENCY_CODE, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { environment } from "@env/environment";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NoAccessComponent } from './components/no-access/no-access.component';
import { MatIconRegistry } from "@angular/material/icon";
import { DOCUMENT, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { SharedSlimModule } from "./shared-slim/shared-slim.module";
import { TranslocoRootModule } from './transloco-root.module';
import { MatDialogModule } from "@angular/material/dialog";
import { HighchartsChartModule } from "highcharts-angular";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { QuillModule } from "ngx-quill";

import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module';

let Quill: any = QuillNamespace;
Quill.register('modules/imageResize', ImageResize);

const authOptions = environment.auth;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NoAccessComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AuthModule.forRoot({
            ...authOptions,
        }),
        BrowserAnimationsModule,
        SharedSlimModule,
        TranslocoRootModule,
        MatDialogModule,
        HighchartsChartModule,
        MatSnackBarModule,
        NgxExtendedPdfViewerModule,
        QuillModule.forRoot(),
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'NOK'},
        {provide: MAT_DATE_LOCALE, useValue: 'nb'},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    private dark = 'theme-dark';

    constructor(private matIconRegistry: MatIconRegistry,  @Inject(DOCUMENT) private document: Document) {
        matIconRegistry.setDefaultFontSetClass('far');

        this.loadTheme();
    }

    private loadTheme() {
        const theme = sessionStorage.getItem('theme');
        if (theme == null || theme !== this.dark)
            return;

        const classes = this.document.documentElement.classList;
        if (classes.contains(this.dark))
            return;

        classes.add(this.dark);
    }
}
