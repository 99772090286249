import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSet } from "@appcore/models/icon";
import { ThemePalette } from "@angular/material/core";


@Component({
    selector: 'app-icon',
    template: `
        <ng-container [ngSwitch]="iconSet != null">
            <ng-container *ngSwitchCase="true">
                <ng-container *ngTemplateOutlet="iconWithFontSet"></ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="iconOnly"></ng-container>
            </ng-container>
        </ng-container>

        <ng-template #iconOnly>
            <mat-icon [fontIcon]="icon" [class]="iconSize" [color]="color"></mat-icon>
        </ng-template>

        <ng-template #iconWithFontSet>
            <mat-icon class="app-icon" *ngIf="!!iconSet" [fontIcon]="iconSet.icon" [fontSet]="iconSet.set"
                      [class]="iconSize" [color]="color"></mat-icon>
        </ng-template>
    `,
    styleUrls: ['icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent{
    @Input() icon: any = '';
    @Input() iconSet: IconSet | undefined;
    @Input() iconSize = '';
    @Input() color: ThemePalette | undefined = 'primary'
    constructor() {
    }
}
