import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import * as $ from 'jquery';
import 'jqueryui'

@Directive({
  selector: '[jDrag]'
})
export class JDragDirective implements OnInit {
  @Input('jDrag') onDrag: any;
  @Input() dragOptions: any;
  @Input() dragData: any;

  @Input() onStartDrag: any;
  @Input() onStopDrag: any;
  
  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    $(this.el.nativeElement).draggable({
      start: this.callDragStartMethod,
      drag: this.callDragMethod,
      stop: this.callStopMethod
    });

    if (this.dragOptions) {
      $(this.el.nativeElement).draggable('option', this.dragOptions);
    }

    if (this.dragData) {
      for (let x of Object.keys(this.dragData)) {
        const value = this.dragData[x];
        if (Array.isArray(value) && (value.length === 0 || value.length > 0 && typeof value[0] === 'object' && value[0] != null)) {
            $(this.el.nativeElement).attr('appendeddragdata-' + x, JSON.stringify(value));          
        } else {          
          $(this.el.nativeElement).attr('appendeddragdata-' + x, value);
        }
      }
    }
  }
  
  callDragStartMethod = (event: any, ui: any) => {
    if (this.onStartDrag) this.onStartDrag(event, ui);
  }

  callDragMethod = (event: any, ui: any) => {
  }

  callStopMethod = (event: any, ui: any) => {
    if (this.onStopDrag) this.onStopDrag(event, ui);
  }
}