import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {UserService} from "@appcore/services/user.service";
import {ComponentBase} from "@appcore/componentbase";

@Component({
    selector: 'app-auth-button',
    template: `
        <ng-container [ngSwitch]="user.isAuthenticated$ | async">
            <ng-container *ngSwitchCase="false">
                <ng-container *ngTemplateOutlet="loginTemplate"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="true">
                <ng-container *ngTemplateOutlet="logoutTemplate"></ng-container>
            </ng-container>
            <div *ngSwitchDefault>...</div>
        </ng-container>

        <ng-template #loginTemplate>
            <ng-container [ngSwitch]="mode" *transloco="let t">
                <app-icon-text-button *ngSwitchCase="'icon-text'" (click)="login()" [iconSet]="icons.login"
                                      [label]="'shared.login' | transloco">
                </app-icon-text-button>
                <app-button *ngSwitchCase="'button'" (click)="login()">{{t('shared.login')}}</app-button>
                <a *ngSwitchCase="'text'" style="cursor: pointer" (click)="login()">{{t('shared.login')}}</a>
                <app-icon-button *ngSwitchDefault (click)="login()" [iconSet]="icons.login"></app-icon-button>
            </ng-container>

        </ng-template>

        <ng-template #logoutTemplate>
            <ng-container [ngSwitch]="mode" *transloco="let t">

                <app-icon-text-button *ngSwitchCase="'icon-text'" (click)="logout()" [iconSet]="icons.logout"
                                      [label]="'shared.logout' | transloco">
                </app-icon-text-button>
                <app-button *ngSwitchCase="'button'" (click)="logout()">{{t('shared.logout')}}</app-button>

                <a *ngSwitchCase="'text'" style="cursor: pointer" (click)="logout()">{{t('shared.logout')}}</a>
                <app-icon-button *ngSwitchDefault (click)="logout()" [iconSet]="icons.logout"></app-icon-button>

            </ng-container>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthButtonComponent extends ComponentBase {
    @Input() mode: 'icon' | 'button' | 'text' | 'icon-text' = 'icon';

    constructor(public user: UserService) {
        super();
    }

    login(): void {
        this.user.login();
    }

    logout(): void {
        this.user.logout();
    }
}
