import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from '@env/environment';
import { firstValueFrom, Subject } from 'rxjs';
import { IMessage } from '../models';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private connection: HubConnection;
    private _messages$ = new Subject<IMessage>();

    constructor(private user: UserService) {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${environment.apiUrl}/hubs/messages`, {
                accessTokenFactory: () => firstValueFrom(this.user.accessToken$)
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    console.info(`SignalR: connection broken for ${retryContext.elapsedMilliseconds / 1000}s, trying to reconnect.`)
                    return Math.random() * 5000;
                }
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.connection.onclose(async err => {
            console.log('SignalR: connection was closed', err);
            await this.connect();
        });

        this.connection.on('NewMessage', (payload) => {
            this._messages$.next(JSON.parse(payload));
        });

        this.user.isAuthenticated$.subscribe(authed => {
            if (authed)
                this.connect();
            else
                this.disconnect();
        })
    }

    get messages$() {
        return this._messages$.asObservable();
    }

    markAsRead(thread: number) {
        return this.connection.invoke('MarkAsRead', thread);
    }

    private async connect() {
        try {
            console.log('SignalR: starting up');
            console.log(this.connection);
            await this.connection.start().then(() => console.log("Connected")).catch((error) => console.log(error));
        } catch (err) {
            console.warn('SignalR: failed to start ', err);
            setTimeout(this.connect, 5000);
        }
    }

    private async disconnect() {
        console.log('SignalR: stopping');
        await this.connection.stop();
    }
}
