import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthButtonComponent} from "./components/buttons/auth-button/auth-button.component";
import {ButtonComponent, ControlButtonComponent} from "./components/buttons/button/button.component";
import {IconButtonComponent} from "./components/buttons/icon-button/icon-button.component";
import {IconTextButtonComponent} from "./components/buttons/icon-text-button/icon-text-button.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {AppcoreModule} from "@appcore/appcore.module";
import {CenteredComponent} from "./components/centered.component";
import {LoadingComponent} from "./components/loading.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {IconComponent} from "./components/icon/icon.component";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { LogoComponent } from './components/logo/logo.component';
import {RouterModule} from "@angular/router";
import {TranslocoModule} from "@ngneat/transloco";
import { JDragDirective } from '@shared/drag-drop/jDrag.directive';
import { JDropDirective } from '@shared/drag-drop/jDrop.directive';

const materialModules = [
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule
];

const components: any[] = [
    AuthButtonComponent, ButtonComponent, ControlButtonComponent, IconButtonComponent, IconTextButtonComponent,
    CenteredComponent, LoadingComponent, IconComponent, LogoComponent,
    JDragDirective,
    JDropDirective
];

/**
 * Slimmed down version of SharedModule that contains components and utilities used by AppModule.
 * Re-exported from SharedModule.
 */
@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AppcoreModule,
        ...materialModules,
        TranslocoModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        AppcoreModule,
        ...materialModules,
        ...components
    ]
})
export class SharedSlimModule {
}
