import { Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";
import * as $ from 'jquery';
import 'jqueryui'

@Directive({
  selector: '[jDrop]'
})
export class JDropDirective implements OnInit {
  @Input() dropOptions: any;
  @Output() onDrop = new EventEmitter<any>();

  constructor(private el: ElementRef, private _ngZone: NgZone) {
  }

  ngOnInit() {
    $(this.el.nativeElement).droppable({
      drop: this.dropTriggered.bind(this)
    });

    if (this.dropOptions) {
      $(this.el.nativeElement).droppable('option', this.dropOptions);
    }

  }
  
  dropTriggered = (event: any, ui: any) => {
    this._ngZone.run(() => {
      const dragDataAttributeName = 'appendeddragdata-'
  
      let dragData = new Map();
      for (const attr of ui.draggable[0].attributes) {
        if(attr.name.includes(dragDataAttributeName)) {
          const key = attr.name.substring(dragDataAttributeName.length);
          dragData.set(key, attr.value);
        }
      }
      const dragDataObject = Object.fromEntries(dragData);
  
      this.onDrop.emit(dragDataObject);
    });
  }
}