import { Injectable } from '@angular/core';
import { AuthHttpService, RoleService } from "@appcore/services/index";
import {
    ICrewWithRotations,
    IVessel,
    IVesselActivity,
    IVesselDetails,
    IVesselForMap,
    IVesselRole,
    IVesselSettlementPeriods,
    IVesselWithFishQuota
} from "@appcore/models";
import { map, Observable, ReplaySubject, take, tap } from "rxjs";
import { SelectedCompany } from "@appcore/services/selected-company";
import { IRole } from "@appcore/models/role";

@Injectable({
    providedIn: 'root'
})
export class VesselService {
    private _vessels$ = new ReplaySubject<IVessel[] | null>(1);
    private _settlementPeriods$ = new ReplaySubject<IVesselSettlementPeriods[] | null>(1);

    private _vesselRoles$ = new ReplaySubject<IVesselRole[] | null>(1);
    private _fetchedPeriodsAt = 0;
    private _fetchedVesselsAt = 0;
    constructor(private authHttp: AuthHttpService, private company: SelectedCompany, private roleService: RoleService) {

    }
    private get baseUrl() {
        const companyId = this.company.lastShippingCompany;
        return `/api/shared/shipping-company/${companyId}/vessel`
    }

    get vessels$(): Observable<IVessel[] | null> {
        this.fetchVessels();

        return this._vessels$.asObservable();
    }

    get settlementPeriods$(): Observable<IVesselSettlementPeriods[] | null> {
        this.fetchSettlementPeriods();

        return this._settlementPeriods$.asObservable();
    }

    getHistoricalPeriods$(vesselId:string): Observable<IVesselSettlementPeriods[] | null> {
        const url = `${this.baseUrl}/settlement-periods/historical/${vesselId}`;
        return this.authHttp.get<IVesselSettlementPeriods[]>(url).pipe(map(c=> c.body));
    }

    vessel$(id: string) {
        return this.authHttp.get<IVesselDetails>(`${this.baseUrl}/${id}`)
            .pipe(map(c => c.body));
    }

	getVesselsWithFishQuota() {
		return this.authHttp.get<IVesselWithFishQuota[]>(this.baseUrl + "/fish-quota").pipe(map(c => c.body));
	}

    createVessel(payload: { name: string }) {
        return this.authHttp.post<IVesselDetails>(this.baseUrl, payload)
            .pipe(
                map(c => c.body),
                tap(() => {
                    this.refresh();
                }));
    }

    updateVessel(id: string, payload: IVesselDetails) {
        this.refresh();
        return this.authHttp.put<IVesselDetails>(`${this.baseUrl}/${id}`, payload)
            .pipe(
                map(c => c.body),
                tap(() => {
                    this.refresh();
                })
            );
    }

    deleteVessel(id: string) {
        return this.authHttp.delete(`${this.baseUrl}/${id}`).pipe(tap(() => {
            this.refresh();
        }));
    }

    get roles$(): Observable<IRole[] | null> {
        return this.roleService.roles$;
    }

    vesselRoles$(id: string): Observable<IVesselRole[] | null> {
        this.fetchVesselRoles(id);
        return this._vesselRoles$.asObservable();
    }

    getVesselRoles(id: string) {
        return this.authHttp.get<IVesselRole[]>(`${this.baseUrl}/${id}/roles`).pipe(map(c => c.body));
    }

	vesselForMap() {
		return this.authHttp.get<IVesselForMap[]>(`${this.baseUrl}/vessel-for-map`).pipe(map(c => c.body));
    }

    private fetchSettlementPeriods() {
        if (this._fetchedPeriodsAt === 0 || Math.floor((Date.now() - this._fetchedPeriodsAt) / 1000) > 60) {
            this._fetchedPeriodsAt = Date.now();
            this.authHttp.get<IVesselSettlementPeriods[]>(`${this.baseUrl}/settlement-periods`)
                .pipe(
                    take(1),
                    map(c => c.body)
                ).subscribe({next: data => this._settlementPeriods$.next(data), error: err => this._settlementPeriods$.error(err)});
            return;
        }
    }

    private fetchVesselRoles(id: string) {
        this.authHttp.get<IVesselRole[]>(`${this.baseUrl}/${id}/roles`)
            .pipe(
                take(1),
                map(c => c.body)
            ).subscribe({next: data => this._vesselRoles$.next(data), error: err => this._vesselRoles$.error(err)});
    }

    private refresh(){
        this._fetchedPeriodsAt = 0;
        this._fetchedVesselsAt = 0;
        this.fetchSettlementPeriods();
        this.fetchVessels();
    }

    updateVesselImage(vesselId: string, image: File) {
        const formData = new FormData();
        formData.append('image', image);

        return this.authHttp.post(
            `${this.baseUrl}/${vesselId}/update-image`,
            formData
        ).pipe(map(c => c.body));
    }

    getCrewAssignedToVessel(vesselId: string) {
        return this.authHttp.get<ICrewWithRotations[]>(`${this.baseUrl}/crew-employed/${vesselId}`)
            .pipe(map(c => c.body));
    }

    getCrewNotAssignedToVesselInSameDepartment(vesselId: string) {
        return this.authHttp.get<ICrewWithRotations[]>(`${this.baseUrl}/other-available-resources/${vesselId}`)
            .pipe(map(c => c.body));
    }

    vesselsWithUniqueRoles$() {
        return this.authHttp.get<IVesselDetails[]>(`${this.baseUrl}/vessel-with-unique-roles`).pipe(map(c => c.body));
    }

    private fetchVessels() {
        if (this._fetchedVesselsAt === 0 || Math.floor((Date.now() - this._fetchedVesselsAt) / 1000) > 60) {
            this._fetchedVesselsAt = Date.now();
            this.authHttp.get<IVessel[]>(this.baseUrl)
                .pipe(
                    take(1),
                    map(c => c.body)
                ).subscribe(vessels => this._vessels$.next(vessels));
            return;
        }
    }

	/*
		Vessel Activity Methods
	 */

    private get vesselActivityBaseUrl() {
		const companyId = this.company.lastShippingCompany;
		return `/api/shared/shipping-company/${companyId}/vessel-activity`;
	}

    getVesselActivity(id: number) {
        return this.authHttp.get<IVesselActivity>(`${this.vesselActivityBaseUrl}/${id}`).pipe(map(c => c.body));
    }

    createVesselActivity(payload: IVesselActivity) {
        if (!payload.voyageNo)
            payload.voyageNo = -1;
        return this.authHttp.post(`${this.vesselActivityBaseUrl}`, payload).pipe(map(c => c.body));
    }

    updateVesselActivity(payload: IVesselActivity) {
        if (!payload.voyageNo)
            payload.voyageNo = -1;
        return this.authHttp.put<boolean>(`${this.vesselActivityBaseUrl}`, payload).pipe(map(c => c.body));
    }

    deleteVesselActivity(id: number) {
        return this.authHttp.delete<boolean>(`${this.vesselActivityBaseUrl}/${id}`).pipe(map(c => c.body));
    }

	getVesselActivities() {
		return this.authHttp.get<IVesselActivity[]>(`${this.vesselActivityBaseUrl}/all`).pipe(map(c => c.body));
	}

    getVesselActivitiesForVessel(vesselId: string, date: string) {
        return this.authHttp.get<IVesselActivity[]>(`${this.vesselActivityBaseUrl}/${vesselId}/${date}`).pipe(map(c => c.body));
    }
}
