import {Component, ChangeDetectionStrategy, Input} from '@angular/core';
import {IconComponent} from "../../icon/icon.component";

@Component({
    selector: 'app-icon-button',
    template: `
        <button mat-icon-button [color]="color" [disabled]="disabled" [type]=type>
            <app-icon [icon]="icon" [iconSet]="iconSet" [iconSize]="iconSize" [color]="color"></app-icon>
        </button>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent extends IconComponent {
    @Input() disabled = false;
    @Input() type = '';
}
