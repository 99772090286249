import {HttpErrorResponse} from "@angular/common/http";

export class AppError {
    title: string | undefined;
    type: string | undefined;
    status: number | undefined;
    traceId: string | undefined;

    constructor(err: any) {
        if (err instanceof HttpErrorResponse && err.status === 0) {
            this.type = err.name;
            this.status = err.status;
            this.title = err.statusText;
            return;
        }

        if (err instanceof HttpErrorResponse) {
            Object.assign(this, err.error);
        } else {
            Object.assign(this, err);
        }
    }
}

export interface IError {
    type: string;
    title: string;
}

export interface IErrorLanguageTranslation {
    [language: string]: IError | undefined;
}

export interface IErrorTranslationContext {
    [context: string]: IErrorLanguageTranslation | undefined;
}

export class ErrorContainer {
    private inner: { [prop: string]: IErrorTranslationContext } = {};
    private static DEFAULT_CONTEXT = 'default_ctx';
    private static DEFAULT_LANGUAGE = 'default_lang';

    add(code: string, contexts: IErrorTranslationContext) {
        this.inner[code] = contexts;
        return this;
    }

    get(code: string, context?: string, language?: string): IError | undefined {
        if (code == null)
            return undefined;

        // Lookup the error-code
        const error = this.inner[code];
        if (error == null)
            return undefined;


        return ErrorContainer.parseContext(error, context ?? ErrorContainer.DEFAULT_CONTEXT, language ?? ErrorContainer.DEFAULT_LANGUAGE);
    }

    private static parseContext(error: IErrorTranslationContext, context: string, language: string): IError | undefined {
        // Find a translation for the given context
        const ctx = error[context];
        if(ctx == null) {

            // There can still be translations for a default context
            if(context !== ErrorContainer.DEFAULT_CONTEXT)
                return ErrorContainer.parseContext(error, ErrorContainer.DEFAULT_CONTEXT, language);

            return undefined;
        }

        return ErrorContainer.parseLanguage(ctx, language);
    }

    private static parseLanguage(ctx: IErrorLanguageTranslation, language: string): IError | undefined {
        const l = ctx[language === ErrorContainer.DEFAULT_LANGUAGE ? language : language?.substring(0, 2)];
        if(!!l)
            return {...l};

        if(language === ErrorContainer.DEFAULT_LANGUAGE)
            return undefined;

        const defaultLang = ctx[ErrorContainer.DEFAULT_LANGUAGE];
        return !!defaultLang ? {...defaultLang} : undefined;
    }

    static parse(errorObj: any) {
        try {
            const container = new ErrorContainer();

            for (const o in errorObj) {
                container.add(o, errorObj[o])
            }

            return container;
        }catch(e) {
            console.warn('Failed to parse error translations', e, errorObj);
            return null;
        }
    }
}
