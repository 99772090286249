import {Component} from '@angular/core';
import {ComponentBase} from "@appcore/componentbase";
import {UserService} from "@appcore/services";
import {take} from "rxjs";
import {TranslocoService} from "@ngneat/transloco";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-user-language-dialog',
    template: `
        <h1 mat-dialog-title>Velg Språk / Select Language</h1>
        <div mat-dialog-actions>
            <button mat-button (click)="selectLanguage('nn')">Nynorsk</button>
            <button mat-button (click)="selectLanguage('nb')">Bokmål</button>
            <button mat-button (click)="selectLanguage('en')">English</button>
        </div>
    `,
    styles: [],
})
export class UserLanguageDialogComponent extends ComponentBase {
    constructor(public dialogRef: MatDialogRef<UserLanguageDialogComponent>,
                private user: UserService, private translocoService: TranslocoService) {
        super();
    }

    selectLanguage(language: string) {
        this.user.updateUserLanguage({language: language}).pipe(take(1)).subscribe(() => {
            this.translocoService.setActiveLang(language);
            // this.dialogRef.close();
            window.location.reload(); // FIXME: Needed because sidenav does not work with rerender
        });
    }
}
