import {Injectable} from "@angular/core";
import {map} from "rxjs";
import {AuthHttpService} from "@appcore/services/index";
import {IRevenueMapping} from "@appcore/models/revenuemapping";
import {IQuotaMapping} from "@appcore/models/quotamapping";
import {IVesselMapping} from "@appcore/models/vesselmapping";
import {IGear} from "@appcore/models/gear";
import {IQuotaType} from "@appcore/models/quotatype";
import {SelectedCompany} from "@appcore/services/selected-company";

@Injectable({
    providedIn: 'root'
})
export class CompanyMappingService {
    constructor(private authHttp: AuthHttpService, private company: SelectedCompany) {
    }

    RevenueMapping$(isExpense: boolean) {
        return this.authHttp.get<IRevenueMapping[]>(`${this.baseUrlRevenue}/${isExpense}`).pipe(map(c => c.body));
    }

    updateRevenueMapping(payload: IRevenueMapping) {
        return this.authHttp.put<IRevenueMapping[]>(`${this.baseUrlRevenue}`, payload).pipe(map(c => c.body));
    }

    createRevenueMapping(payload: IRevenueMapping) {
        return this.authHttp.post<IRevenueMapping>(`${this.baseUrlRevenue}`, payload).pipe(map(c => c.body));
    }

    deleteRevenueMapping(id: number) {
        return this.authHttp.delete<IRevenueMapping>(`${this.baseUrlRevenue}/${id}`).pipe(map(c => c.body));
    }

    QuotaMapping$() {
        return this.authHttp.get<IQuotaMapping[]>(this.baseUrlQuota).pipe(map(c => c.body));
    }

    QuotaMappingWithProject$() {
        return this.authHttp.get<IQuotaMapping[]>(`${this.baseUrlQuota}/with-project`).pipe(map(c => c.body));
    }

    createQuotaMapping(payload: IQuotaMapping) {
        return this.authHttp.post<IQuotaMapping>(this.baseUrlQuota, payload).pipe(map(c => c.body));
    }

    updateQuotaMapping(id: number, payload: IQuotaMapping) {
        return this.authHttp.put<IQuotaMapping[]>(`${this.baseUrlQuota}/${id}`, payload).pipe(map(c => c.body));
    }

    deleteQuotaMapping(id: number) {
        return this.authHttp.delete<IQuotaMapping>(`${this.baseUrlQuota}/${id}`).pipe(map(c => c.body));
    }

    VesselMapping$() {
        return this.authHttp.get<IVesselMapping[]>(`${this.baseUrlVessel}`).pipe(map(c => c.body));
    }

    updateVesselMapping(payload: IVesselMapping[]) {
        return this.authHttp.put<IVesselMapping[]>(`${this.baseUrlVessel}`, payload).pipe(map(c => c.body));
    }

    Gear$() {
        return this.authHttp.get<IGear[]>(this.baseUrlGear).pipe(map(c => c.body));
    }

    createGear(payload: IGear) {
        return this.authHttp.post<IGear>(this.baseUrlGear, payload).pipe(map(c => c.body));
    }

    updateGear(payload: IGear) {
        return this.authHttp.put<IGear>(this.baseUrlGear, payload).pipe(map(c => c.body));
    }

    deleteGear(id: number) {
        return this.authHttp.delete<IGear>(`${this.baseUrlGear}/${id}`).pipe(map(c => c.body));
    }

    QuotaTypes$() {
        return this.authHttp.get<IQuotaType[]>(this.baseUrlQuotaType).pipe(map(c => c.body));
    }

    createQuotaType(payload: IQuotaType) {
        return this.authHttp.post<IQuotaType>(this.baseUrlQuotaType, payload).pipe(map(c => c.body));
    }

    updateQuotaType(payload: IQuotaType) {
        return this.authHttp.put<IQuotaType>(this.baseUrlQuotaType, payload).pipe(map(c => c.body));
    }

    deleteQuotaType(id: number) {
        return this.authHttp.delete<IQuotaType>(`${this.baseUrlQuotaType}/${id}`).pipe(map(c => c.body));
    }

    private get baseUrlRevenue(){
        return `/api/shipping-company/${this.company.lastShippingCompany}/revenue-mapping`;
    }

    private get baseUrlQuota(){
        return `/api/shipping-company/${this.company.lastShippingCompany}/quota-mapping`;
    }

    private get baseUrlVessel(){
        return `/api/shipping-company/${this.company.lastShippingCompany}/vessel-mapping`;
    }

    private get baseUrlGear(){
        return `/api/shipping-company/${this.company.lastShippingCompany}/gear`;
    }

    private get baseUrlQuotaType(){
        return `/api/shipping-company/${this.company.lastShippingCompany}/quota-type-mapping`;
    }
}
